import React, { useState } from 'react';
import './WatchList.css';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';

const WatchList = () => {
    // Mocking a list of movies
    const [watchList, setWatchList] = useState([
        {
            imdbId: 'tt1375666',
            title: 'Tumbbad',
            poster: 'https://image.tmdb.org/t/p/w400/yYZcWQhnzv3fhxc9PP3mqyRVygQ.jpg',
            trailerLink: 'https://www.youtube.com/watch?v=YGIcZrUBY0k',
        },
        {
            imdbId: 'tt1478839',
            title: 'The Art of Racing in the Rain',
            poster: 'https://image.tmdb.org/t/p/w400/mi5VN4ww0JZgRFJIaPxxTGKjUg7.jpg',
            trailerLink: 'https://www.youtube.com/watch?v=Dp2ufFO4QGg',
        },
        {
             imdbId: 'tt26458038',
             title: 'Manjummel Boys',
             poster: 'https://image.tmdb.org/t/p/original/bswrtewwthpsh6nABiqKevU4UBI.jpg',
             trailerLink: 'https://www.youtube.com/watch?v=id848Ww1YLo',
        },


    ]);

    return (
        <div className="watchlist-container">
            <h2 className="watchlist-title">Your Watchlist</h2>
            <Container>
                <Row className="watchlist-movies">
                    {watchList.map((movie) => (
                        <Col key={movie.imdbId} xs={12} sm={6} md={4} lg={3}>
                            <div className="watchlist-movie-card">
                                <img src={movie.poster} alt={movie.title} className="watchlist-movie-poster" />
                                <h3 className="watchlist-movie-title">{movie.title}</h3>
                                <Link to={`/Trailer/${movie.trailerLink.split('v=')[1]}`}>
                                    <Button variant="primary">Watch Trailer</Button>
                                </Link>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default WatchList;

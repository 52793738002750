import { Outlet } from "react-router-dom";

import React from 'react'

const Layout = () => {
  return (
    <main style={{ margin: 0, padding: 0 }}>
      <Outlet />
    </main>
  )
  }

export default Layout